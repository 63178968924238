import WidgetsIcon from "@mui/icons-material/Widgets";
import GroupIcon from "@mui/icons-material/Group";

const NavLinks = [
  {
    menu_title: "Dashboard",
    menu_icon: (props = {}) => <WidgetsIcon {...props} />,
    new_item: false,
    path: "/dashboard",
  },
  {
    menu_title: "Manage Users",
    menu_icon: (props = {}) => <GroupIcon {...props} />,
    new_item: false,
    path: "/users",
    onlyAdmin: true,
  },
];

export default NavLinks;
