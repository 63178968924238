import BlockIcon from "@mui/icons-material/Block";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PauseIcon from "@mui/icons-material/Pause";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import AlarmOnIcon from "@mui/icons-material/AlarmOn";
import { FeederStatus, IFeeder } from "../../../types/feeder";

export default function FeederStatusIcon({
  status,
  feeder,
}: {
  status: FeederStatus;
  feeder?: IFeeder;
}) {
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "3px",
        borderRadius: "100px",
        backgroundColor:
          status == FeederStatus.Active
            ? "#00ff0020"
            : status == FeederStatus.Paused
            ? "#d3750121"
            : undefined,
      }}
    >
      {status == FeederStatus.Active ? (
        feeder &&
        (!feeder.startTime || !feeder.endTime || !feeder.intervalMins) ? (
          <PanoramaFishEyeIcon color="success" />
        ) : feeder &&
          feeder.startTime &&
          feeder.endTime &&
          feeder.intervalMins &&
          new Date(feeder.startTime) > new Date() ? (
          <AlarmOnIcon color="success" />
        ) : feeder &&
          feeder.startTime &&
          feeder.endTime &&
          feeder.intervalMins &&
          new Date(feeder.endTime) < new Date() ? (
          <HourglassBottomIcon color="warning" />
        ) : (
          <FiberManualRecordIcon color="success" />
        )
      ) : status == FeederStatus.NoTask ? (
        <FiberManualRecordIcon sx={{ opacity: 0.5 }} />
      ) : status == FeederStatus.Paused ? (
        <PauseIcon color="warning" />
      ) : (
        <BlockIcon sx={{ opacity: 0.5 }} />
      )}
    </div>
  );
}
