/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { Grid } from "@mui/material";
import FeedersTable from "../../components/Feeder/FeedersTable";
export default function Index() {
  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FeedersTable />
        </Grid>
      </Grid>
    </>
  );
}
