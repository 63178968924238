import { encryptStorageUtils } from "../service/encryptStorage";
import { ILoggedInUserInfo } from "../types/auth";
import { ActionType, StateType, actionTypes } from "../types/state";
import { IUser } from "../types/user";

export const initialState = {
  openSideDrawer: false,
};

export const actionAppReducer = (
  state: StateType,
  action: ActionType
): StateType => {
  switch (action.type) {
    case actionTypes.setAllState:
      return action.value as StateType;
    case actionTypes.setOpenSideDrawer:
      return {
        ...state,
        openSideDrawer: action.value as boolean,
      };
    case actionTypes.setMyProfile:
      return {
        ...state,
        myProfile: action.value as IUser | undefined,
      };
    case actionTypes.setLoggedInUserInfo:
      const loggedInInfo: ILoggedInUserInfo = action.value as ILoggedInUserInfo;
      encryptStorageUtils.setAuthTokenFromStorage(
        loggedInInfo?.authToken || ""
      );
      return {
        ...state,
        loggedInUserInfo: action.value as ILoggedInUserInfo | undefined,
      };
    default:
      return state;
  }
};
