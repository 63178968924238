/* eslint-disable @typescript-eslint/no-explicit-any */
import { FeederStatus, IFeeder } from "../types/feeder";
import backendApi from "./backendApi";
import { showSnackbar } from "./snackbar.service";

const routePrefix = "feeder";

export default {
  async getFeeders(): Promise<any> {
    try {
      const result = await backendApi()?.get(`${routePrefix}/all`);
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async createFeeder(payload: IFeeder): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/add`, payload);
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async updateFeeder(payload: IFeeder): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/update`, {
        feeder: payload,
      });
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async updateFeederStatus(id: string, status: FeederStatus): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/update-status`, {
        feederId: id,
        status: status,
      });
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
  async deleteFeeder(id: string): Promise<any> {
    try {
      const result = await backendApi()?.post(`${routePrefix}/delete/${id}`);
      return result.data;
    } catch (error: any) {
      if (error?.response?.status) {
        showSnackbar(
          "danger",
          "Error",
          error?.response?.data?.message || "API error response"
        );
        return null;
      }
    }
  },
};
