import Box from "@mui/material/Box";
import * as React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { InfoTag } from "../Common/ListItemInfoTag";
import { fDateTime, fToNow } from "../../../helpers/formatTime";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { IActionMetadata } from "../../../types/feeder";

export default function ActionHistoryItem({
  action,
}: {
  action: IActionMetadata;
}) {
  return (
    <Box px={2} py={1} sx={{ position: "relative", flex: 1 }}>
      <Box
        sx={{
          fontSize: "14px",
          opacity: "0.8",
          marginBottom: "6px",
          "& .MuiTableCell-root": {
            padding: "4px 12px 4px 0px !important",
          },
        }}
      >
        {action.actionTitle ? (
          <Box
            sx={{ fontWeight: "500", color: "#007ad0", marginBottom: "3px" }}
          >
            {action.actionTitle}
          </Box>
        ) : null}

        <Table size="small" aria-label="updated fields table">
          <TableBody>
            {action.actionItems?.map((item, index) => (
              <TableRow key={index}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold" }}
                >
                  {item.key}
                </TableCell>
                <TableCell align="left">{item.originalValue || ""}</TableCell>
                <TableCell align="left">
                  <ArrowForwardIcon color="warning" />
                </TableCell>
                <TableCell align="left">{item.value || ""}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          opacity: "0.8",
        }}
      >
        <InfoTag>
          <PersonOutlineIcon sx={{ fontSize: "14px", mr: "2px" }} />
          {action.fullName}
        </InfoTag>
        <InfoTag
          sx={{
            "&:hover .from-now": { display: "none !important" },
            "&:hover .exact-date": { display: "inline !important" },
          }}
        >
          <AccessTimeIcon sx={{ fontSize: "14px", mr: "2px" }} />
          <div className="from-now">{fToNow(action.time)}</div>
          <div className="exact-date" style={{ display: "none" }}>
            {fDateTime(action.time)}
          </div>
        </InfoTag>
      </Box>
    </Box>
  );
}
