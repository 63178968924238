/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { IFeeder } from "../../../types/feeder";
import dayjs from "dayjs";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ProgressButton from "../Common/ProgressButton";
import feederService from "../../../service/feeder.service";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Alert from "@mui/material/Alert";
import { showSnackbar } from "../../../service/snackbar.service";

interface FeederFormDialogProps {
  open: boolean;
  onClose: () => void;
  feeder?: IFeeder;
  onNewCreated: (created: IFeeder) => void;
  onUpdated: (updated: IFeeder) => void;
}

const calculateEndTime = (
  startTime: Date | undefined,
  intervalMins: number | undefined,
  numberOfFeeds: number | undefined
) => {
  if (!startTime || !intervalMins || !numberOfFeeds) {
    return undefined;
  }
  const endTime = dayjs(startTime)
    .add(intervalMins * numberOfFeeds, "minutes")
    .add(1, "minutes");
  return endTime.toDate();
};

const FeederFormDialog: React.FC<FeederFormDialogProps> = ({
  open,
  onClose,
  feeder,
  onNewCreated,
  onUpdated,
}) => {
  const [editingFeeder, setEditingFeeder] = useState<IFeeder | undefined>(
    feeder
  );
  const [processing, setProcessing] = useState(false);
  const handleCreate = async () => {
    if (!editingFeeder) {
      return;
    }
    setProcessing(true);
    const result = await feederService.createFeeder(editingFeeder);
    setProcessing(false);
    if (result) {
      onNewCreated(result);
    }
  };
  const handleUpdate = async () => {
    if (!editingFeeder) {
      return;
    }
    try {
      setProcessing(true);
      const result = await feederService.updateFeeder(editingFeeder);
      setProcessing(false);
      onUpdated(result);
    } catch (error: any) {
      showSnackbar(
        "danger",
        "Error",
        error?.response?.data?.message || "API error response"
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ px: { xs: 2, sm: 3 } }}>Feeder Details</DialogTitle>
      <DialogContent
        sx={{
          px: { xs: 2, sm: 3 },
          width: { xs: "300px", sm: "450px" },
        }}
      >
        <Stack spacing={3} sx={{ py: 1 }}>
          <TextField
            label="Feeder Name"
            size="small"
            value={editingFeeder?.name}
            onChange={(e) =>
              setEditingFeeder({ ...editingFeeder, name: e.target.value || "" })
            }
          />
          <TextField
            label="ID Token"
            size="small"
            value={editingFeeder?.token}
            onChange={(e) =>
              setEditingFeeder({
                ...editingFeeder,
                token: e.target.value || "",
              })
            }
          />
          <Stack direction="row" spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={
                  editingFeeder?.startTime
                    ? dayjs(editingFeeder?.startTime)
                    : null
                }
                onChange={(newValue) =>
                  setEditingFeeder({
                    ...editingFeeder,
                    startTime: newValue?.toDate(),
                    endTime: calculateEndTime(
                      newValue?.toDate(),
                      editingFeeder?.intervalMins,
                      editingFeeder?.numberOfFeeds
                    ),
                  })
                }
                slotProps={{ textField: { size: "small", fullWidth: true } }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Start Time"
                value={
                  editingFeeder?.startTime
                    ? dayjs(editingFeeder?.startTime)
                    : null
                }
                onChange={(newValue) =>
                  setEditingFeeder({
                    ...editingFeeder,
                    startTime: newValue?.toDate(),
                    endTime: calculateEndTime(
                      newValue?.toDate(),
                      editingFeeder?.intervalMins,
                      editingFeeder?.numberOfFeeds
                    ),
                  })
                }
                slotProps={{
                  textField: { size: "small", fullWidth: true },
                }}
              />
            </LocalizationProvider>
          </Stack>
          <Stack direction="row" spacing={2}>
            <TextField
              type="number"
              label="Interval (mins)"
              size="small"
              value={editingFeeder?.intervalMins}
              onChange={(e) =>
                setEditingFeeder({
                  ...editingFeeder,
                  intervalMins: parseInt(e.target.value) || undefined,
                  endTime: calculateEndTime(
                    editingFeeder?.startTime,
                    parseInt(e.target.value) || undefined,
                    editingFeeder?.numberOfFeeds
                  ),
                })
              }
            />
            <TextField
              type="number"
              label="Number of Feeds"
              size="small"
              value={editingFeeder?.numberOfFeeds}
              onChange={(e) =>
                setEditingFeeder({
                  ...editingFeeder,
                  numberOfFeeds: parseInt(e.target.value) || undefined,
                  endTime: calculateEndTime(
                    editingFeeder?.startTime,
                    editingFeeder?.intervalMins,
                    parseInt(e.target.value) || undefined
                  ),
                })
              }
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled
                label="End Date"
                value={
                  editingFeeder?.endTime ? dayjs(editingFeeder?.endTime) : null
                }
                onChange={(newValue) =>
                  setEditingFeeder({
                    ...editingFeeder,
                    endTime: newValue?.toDate(),
                  })
                }
                slotProps={{ textField: { size: "small", fullWidth: true } }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                disabled
                label="End Time"
                value={
                  editingFeeder?.endTime ? dayjs(editingFeeder?.endTime) : null
                }
                onChange={(newValue) =>
                  setEditingFeeder({
                    ...editingFeeder,
                    endTime: newValue?.toDate(),
                  })
                }
                slotProps={{
                  textField: { size: "small", fullWidth: true },
                }}
              />
            </LocalizationProvider>
          </Stack>

          {!editingFeeder?.intervalMins ||
          !editingFeeder?.startTime ||
          !editingFeeder?.numberOfFeeds ? (
            <Alert severity="warning">
              Start Time, Interval and Number of Feeds are required for a feeder
              to work.
            </Alert>
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {feeder ? (
          <ProgressButton
            disabled={!editingFeeder?.name || !editingFeeder?.token}
            onClick={handleUpdate}
            inProgress={processing}
            title="Save"
          />
        ) : (
          <ProgressButton
            disabled={!editingFeeder?.name || !editingFeeder?.token}
            onClick={handleCreate}
            inProgress={processing}
            title="Add Feeder"
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FeederFormDialog;
