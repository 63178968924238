/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import Box from "@mui/material/Box";
import * as React from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { DataGridPro } from "@mui/x-data-grid-pro";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import WhitePanel from "../../components/Common/WhitePanel";
import Box from "@mui/material/Box";
import SimpleSearchInput from "../../components/Common/SimpleSearchInput";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { IUser } from "../../../types/user";
import userService from "../../../service/user.service";
import UserFormDialog from "./UserFormDialog";
import GeneralPopoverWrapper from "../../components/Common/GeneralPopoverWrapper";
import { camelCaseToHumanReadable } from "../../../helpers/utils";
import ConfirmDialog from "../../components/Common/ConfirmDialog";
import UserAvatar from "../../components/User/UserAvatar";

export default function Index() {
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [tableLoading, setTableLoading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [openUserFormDialog, setOpenUserFormDialog] = React.useState(false);
  const [targetUser, setTargetUser] = React.useState<IUser>();
  const [openDeleteUserConfirmDialog, setOpenDeleteUserConfirmDialog] =
    React.useState(false);
  const [deletingUserInProgress, setDeletingUserInProgress] =
    React.useState(false);

  const columns = [
    {
      field: "avatar",
      headerName: "Avatar",
      width: 70,
      editable: true,
      renderCell: (params: any) => (
        <UserAvatar user={params.row} sx={{ width: "35px", height: "35px" }} />
      ),
    },
    {
      field: "fullname",
      headerName: "Full Name",
      width: 150,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: true,
    },
    {
      field: "role",
      headerName: "Role",
      sortable: false,
      width: 160,
      valueGetter: (params: any) =>
        params.value ? `${camelCaseToHumanReadable(params.value)}` : "",
    },
    {
      field: "actions",
      headerName: "Action",
      width: 80,
      editable: false,
      sortable: false,
      disableColumnMenu: true,
      disableReorder: true,
      renderCell: (params: any) => {
        return (
          <Box>
            <GeneralPopoverWrapper
              closeOnClickContent
              triggerElement={
                <IconButton size="small">
                  <MoreVertIcon />
                </IconButton>
              }
              popoverContent={
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      setTargetUser(params.row);
                      setOpenUserFormDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>Edit User Info</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setTargetUser(params.row);
                      setOpenDeleteUserConfirmDialog(true);
                    }}
                  >
                    <ListItemIcon>
                      <DeleteOutlineIcon color="error" />
                    </ListItemIcon>
                    <ListItemText>Delete User</ListItemText>
                  </MenuItem>
                </MenuList>
              }
            />
          </Box>
        );
      },
    },
  ];

  const fetchUsers = async () => {
    setTableLoading(true);
    const result = await userService.fetchAllUsers();
    if (result) {
      setUsers(result);
    }
    setTableLoading(false);
  };

  const handleDeleteUser = async () => {
    if (!targetUser?._id) return;
    setDeletingUserInProgress(true);
    await userService.deleteUser(targetUser?._id || "");
    setDeletingUserInProgress(false);
    setUsers(users.filter((u) => u._id != targetUser._id));
    setTargetUser(undefined);
  };

  const getFilteredUsers = React.useCallback(() => {
    return users.filter(
      (u) =>
        u.fullname?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
        u.email?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );
  }, [users, searchTerm]);

  React.useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <Box mb={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "24px",
            fontWeight: "500",
            color: "secondary.main",
          }}
        >
          Manage Users
          <Box
            sx={{
              ml: 1,
              fontSize: "24px",
              fontWeight: "500",
              opacity: "0.6",
              color: "secondary.main",
            }}
          >{`(${users.length})`}</Box>
        </Box>
      </Box>
      <WhitePanel>
        <Box
          pb={2}
          sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <Box>
            <SimpleSearchInput
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClear={() => setSearchTerm("")}
              size="small"
              placeholder="Search Users"
            />
          </Box>

          {selectedIds?.length ? (
            <Button
              startIcon={<DeleteIcon />}
              disableElevation
              variant="contained"
              sx={{ ml: 2 }}
              color="error"
            >
              Delete
            </Button>
          ) : null}
          <Box sx={{ flex: 1 }} />

          <Button
            onClick={() => {
              setTargetUser(undefined);
              setOpenUserFormDialog(true);
            }}
            startIcon={<AddCircleOutlineIcon />}
            disableElevation
            variant="contained"
            sx={{ ml: 2 }}
          >
            Add New User
          </Button>
        </Box>
        <DataGridPro
          loading={tableLoading || deletingUserInProgress}
          autoHeight
          // selectionModel={selectedIds}
          // onSelectionModelChange={(changedIds: any) => {
          //   setSelectedIds(changedIds);
          // }}
          rows={getFilteredUsers()}
          columns={columns}
          // checkboxSelection
          disableRowSelectionOnClick
        />
        {openUserFormDialog && (
          <UserFormDialog
            open
            user={targetUser}
            onClose={() => setOpenUserFormDialog(false)}
            onNewCreated={(created) => {
              setUsers([created, ...users]);
              setOpenUserFormDialog(false);
            }}
            onUpdated={(updatedUser) => {
              const tempUsers = [...users];
              const targetIndex = tempUsers.findIndex(
                (p) => p._id == updatedUser._id
              );
              if (targetIndex > -1) {
                tempUsers.splice(targetIndex, 1, updatedUser);
                setUsers(tempUsers);
                setOpenUserFormDialog(false);
              }
            }}
          />
        )}
      </WhitePanel>
      {openDeleteUserConfirmDialog && targetUser ? (
        <ConfirmDialog
          title={<>Delete User {`"${targetUser.fullname}"`}</>}
          confirmButtonColor={"error"}
          content={
            <>{`Are you sure you want to delete user "${targetUser.fullname}"?`}</>
          }
          onCancel={() => setOpenDeleteUserConfirmDialog(false)}
          onConfirm={() => {
            handleDeleteUser();
          }}
          onConfirmText={<>Delete</>}
        />
      ) : null}
    </>
  );
}
