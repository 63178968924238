/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { FeederStatus, IFeeder } from "../../../types/feeder";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { fDateTime, fToNow } from "../../../helpers/formatTime";
import { useState } from "react";

interface IConnectedPeriod {
  status: "connected" | "disconnected" | "paused";
  start: Date;
  end: Date;
}

export default function FeederPerformanceMiniGraph({
  feeder,
  daysToShow = 3,
}: {
  feeder: IFeeder;
  daysToShow: number;
}) {
  const [startTime, setStartTime] = useState(
    new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * daysToShow)
  );
  // const startTime = new Date(
  //   new Date().getTime() - 1000 * 60 * 60 * 24 * daysToShow
  // );
  const connectedTimes = React.useMemo(() => {
    return (feeder.connectedTimes || [])
      .map((t) => new Date(t))
      .filter((t) => t > startTime);
  }, [startTime, feeder]);

  const disconnectedTimes = React.useMemo(() => {
    return (feeder.disconnectedTimes || [])
      .map((t) => new Date(t))
      .filter((t) => t > startTime);
  }, [startTime, feeder]);

  const feedTimes = React.useMemo(() => {
    return (feeder.feedTimes || [])
      .map((t) => new Date(t))
      .filter((t) => t > startTime);
  }, [startTime, feeder]);

  const pausedTimes = React.useMemo(() => {
    return (feeder.pausedTimes || [])
      .map((t) => new Date(t))
      .filter((t) => t > startTime);
  }, [startTime, feeder]);

  const resumedTimes = React.useMemo(() => {
    return (feeder.resumedTimes || [])
      .map((t) => new Date(t))
      .filter((t) => t > startTime);
  }, [startTime, feeder]);
  // const disconnectedTimes = (feeder.disconnectedTimes || [])
  //   .map((t) => new Date(t))
  //   .filter((t) => t > startTime);
  // const feedTimes = (feeder.feedTimes || [])
  //   .map((t) => new Date(t))
  //   .filter((t) => t > startTime);
  // const pausedTimes = (feeder.pausedTimes || [])
  //   .map((t) => new Date(t))
  //   .filter((t) => t > startTime);
  // const resumedTimes = (feeder.resumedTimes || [])
  //   .map((t) => new Date(t))
  //   .filter((t) => t > startTime);

  const allPeriodItems = React.useMemo(() => {
    const allPeriods: IConnectedPeriod[] = [];

    const connectedPeriodsItems: IConnectedPeriod[] = connectedTimes.map(
      (t, i) => {
        const disconnectedTime = disconnectedTimes.find((dt) => dt > t);
        return {
          status: "connected",
          start: t,
          end: disconnectedTime || new Date(),
        };
      }
    );

    allPeriods.push(...connectedPeriodsItems);

    const disconnectedPeriodsItems: IConnectedPeriod[] = disconnectedTimes.map(
      (t, i) => {
        const connectedTime = connectedTimes.find((ct) => ct > t);
        return {
          status: "disconnected",
          start: t,
          end: connectedTime || new Date(),
        };
      }
    );
    allPeriods.push(...disconnectedPeriodsItems);

    const pausedPeriodsItems: IConnectedPeriod[] = pausedTimes.map((t, i) => {
      const resumedTime = resumedTimes.find((rt) => rt > t);
      return {
        status: "paused",
        start: t,
        end: resumedTime || new Date(),
      };
    });
    allPeriods.push(...pausedPeriodsItems);

    if (!connectedTimes?.length && !disconnectedTimes?.length) {
      allPeriods.push({
        status:
          feeder.status == FeederStatus.Active ? "connected" : "disconnected",
        start: startTime,
        end: new Date(),
      });
    } else {
      const firstTimePoint = Math.min(
        (connectedTimes?.[0] || new Date()).getTime(),
        (disconnectedTimes?.[0] || new Date()).getTime()
      );
      allPeriods.push({
        status:
          (connectedTimes?.[0] || new Date()) <
          (disconnectedTimes?.[0] || new Date())
            ? "disconnected"
            : "connected",
        start: startTime,
        end: new Date(firstTimePoint),
      });
    }
    return allPeriods;
  }, [
    startTime,
    feeder.connectedTimes,
    feeder.disconnectedTimes,
    feeder.pausedTimes,
    feeder.resumedTimes,
  ]);

  React.useEffect(() => {
    setStartTime(
      new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * daysToShow)
    );
  }, [daysToShow, feeder]);

  return (
    <Box sx={{ width: "100%" }}>
      <div style={{ marginBottom: "17px", position: "relative" }}>
        {feedTimes.map((t, i) => (
          <Tooltip
            title={`Fed at ${fDateTime(t, "yyyy/MM/dd HH:mm")}`}
            arrow
            placement="top"
          >
            <div
              key={i}
              style={{
                position: "absolute",
                left: `${
                  ((t.getTime() - startTime.getTime()) /
                    (new Date().getTime() - startTime.getTime())) *
                  100
                }%`,
                transform: "translateX(-50%)",
                width: "1px",
                height: "15px",
                // borderRadius: "8px",
                backgroundColor: "#00de01",
              }}
            >
              <div
                key={i}
                style={{
                  position: "absolute",
                  left: `${
                    ((t.getTime() - startTime.getTime()) /
                      (new Date().getTime() - startTime.getTime())) *
                    100
                  }%`,
                  transform: "translateX(-50%)",
                  width: "0px",
                  height: "0px",
                  borderTop: "7px solid #00de01",
                  borderLeft: "5px solid #0000",
                  borderRight: "5px solid #0000",
                }}
              ></div>
            </div>
          </Tooltip>
        ))}
      </div>
      <div
        style={{
          width: "100%",
          height: "8px",
          backgroundColor: "#ddd",
          position: "relative",
          borderRadius: "2px",
          overflow: "hidden",
        }}
      >
        {allPeriodItems.map((p, i) => (
          <Tooltip
            title={`Being ${p.status} from ${fDateTime(
              p.start,
              "yyyy/MM/dd HH:mm"
            )} to ${fDateTime(p.end, "yyyy/MM/dd HH:mm")}`}
            arrow
            placement="top"
          >
            <Box
              key={i}
              sx={{
                minWidth: "1px",
                position: "absolute",
                left: `${
                  ((p.start.getTime() - startTime.getTime()) /
                    (new Date().getTime() - startTime.getTime())) *
                  100
                }%`,
                width: `${
                  ((p.end.getTime() - p.start.getTime()) /
                    (new Date().getTime() - startTime.getTime())) *
                  100
                }%`,
                height: "100%",
                backgroundColor:
                  p.status == "connected"
                    ? "#00de01"
                    : p.status == "paused"
                    ? "#ffa638"
                    : "error.main",
              }}
            ></Box>
          </Tooltip>
        ))}
      </div>
      <div
        style={{
          fontSize: "12px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>{fToNow(startTime)}</div>
        <div>Now</div>
      </div>
    </Box>
  );
}
