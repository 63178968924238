/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { FeederStatus, IActionMetadata, IFeeder } from "../../../types/feeder";
import WhitePanel from "../Common/WhitePanel";
import Box from "@mui/material/Box";
import SimpleSearchInput from "../Common/SimpleSearchInput";
import Button from "@mui/material/Button";
import { DataGridPro } from "@mui/x-data-grid-pro";
import ConfirmDialog from "../Common/ConfirmDialog";
import FeederFormDialog from "./FeederFormDialog";
import feederService from "../../../service/feeder.service";
import { fDateTime, fTime, fToNow } from "../../../helpers/formatTime";
import { IconButton, MenuItem, Paper, TextField, Tooltip } from "@mui/material";
import FeederStatusIcon from "./FeederStatusIcon";
import { camelCaseToHumanReadable } from "../../../helpers/utils";
import AddIconButton from "../Common/AddIconButton";
import useWindowWidth from "../../../hooks/useWindowWidth";
import GeneralPopoverWrapper from "../Common/GeneralPopoverWrapper";
import ActionHistoryItem from "./ActionHistoryItem";
import { showSnackbar } from "../../../service/snackbar.service";
import FeederPerformanceMiniGraph from "./FeederPerformanceMiniGraph";
import { UserRoles } from "../../../types/auth";

let feedersData: IFeeder[] = [];
export default function FeedersTable() {
  const [feeders, setFeeders] = React.useState<IFeeder[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [openFeederFormDialog, setOpenFeederFormDialog] = React.useState(false);
  const [targetFeeder, setTargetFeeder] = React.useState<IFeeder>();
  const [openDeleteFeederConfirmDialog, setOpenDeleteFeederConfirmDialog] =
    React.useState(false);
  const [performanceDaysToShow, setPerformanceDaysToShow] = React.useState(3);
  const handleChangeStatus = async (feederId: string, status: FeederStatus) => {
    try {
      setLoading(true);
      await feederService.updateFeederStatus(feederId, status);
      showSnackbar(
        "success",
        "Action succeeded",
        "Status changed successfully"
      );
      fetchFeeders();
    } catch (error: any) {
      showSnackbar(
        "danger",
        "Failed to change status",
        error?.response?.message || ""
      );
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 120,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>{params.value}</div>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <FeederStatusIcon status={params.value} feeder={params.row} />
            <div style={{ marginLeft: "8px", marginRight: "0px", flex: 1 }}>
              {params.value == FeederStatus.Active &&
              params.row.startTime &&
              params.row.endTime &&
              params.row.intervalMins &&
              new Date(params.row.startTime) > new Date()
                ? "Scheduled"
                : params.value == FeederStatus.Active &&
                  params.row.startTime &&
                  params.row.endTime &&
                  params.row.intervalMins &&
                  new Date(params.row.endTime) < new Date()
                ? "Timed Out"
                : params.value == FeederStatus.Active &&
                  (!params.row.startTime ||
                    !params.row.endTime ||
                    !params.row.intervalMins)
                ? "No Task"
                : camelCaseToHumanReadable(params.value || "")}
            </div>
            {/* {params.row.status == FeederStatus.Active &&
            params.row.startTime &&
            params.row.endTime &&
            params.row.intervalMins &&
            new Date(params.row.startTime) < new Date() &&
            new Date(params.row.endTime) > new Date() ? (
              <Tooltip title="Pause Now" placement="right" arrow>
                <IconButton
                  color="warning"
                  size="small"
                  onClick={() =>
                    handleChangeStatus(params.row._id, FeederStatus.Paused)
                  }
                >
                  <PauseCircleIcon sx={{ fontSize: "28px" }} />
                </IconButton>
              </Tooltip>
            ) : params.row.status == FeederStatus.Paused ? (
              <Tooltip title="Resume Now" placement="right" arrow>
                <IconButton
                  color="success"
                  size="small"
                  onClick={() =>
                    handleChangeStatus(params.row._id, FeederStatus.Active)
                  }
                >
                  <PlayCircleIcon sx={{ fontSize: "28px" }} />
                </IconButton>
              </Tooltip>
            ) : null} */}
          </div>
        );
      },
    },
    {
      field: "performance",
      headerName: "Performance",
      width: 300,
      sortable: false,
      renderHeader: (params: any) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <div style={{ fontWeight: "500", marginRight: "8px" }}>
              Performance for{" "}
            </div>
            <TextField
              select
              fullWidth
              placeholder="Timeline Days"
              size="small"
              value={performanceDaysToShow}
              onChange={(e) => {
                setPerformanceDaysToShow(e.target.value as unknown as number);
              }}
            >
              <MenuItem value={1}>1 Day</MenuItem>
              <MenuItem value={3}>3 Days</MenuItem>
              <MenuItem value={7}>1 Week</MenuItem>
              <MenuItem value={14}>2 Weeks</MenuItem>
              <MenuItem value={30}>1 Month</MenuItem>
            </TextField>
          </Box>
        );
      },
      renderCell: (params: any) => (
        <FeederPerformanceMiniGraph
          feeder={params.row}
          daysToShow={performanceDaysToShow}
        />
      ),
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 180,
      valueGetter: (params: any) =>
        params.value ? fDateTime(params.value, "yyyy/MM/dd HH:mm") : "",
      renderCell: (params: any) => {
        return (
          <div>
            <div>
              {params.value ? fDateTime(params.value, "yyyy/MM/dd HH:mm") : ""}
            </div>
            {params.value ? (
              <div style={{ opacity: 0.5, fontSize: "12px" }}>
                {fToNow(params.value)}
              </div>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "intervalMins",
      headerName: "Interval (mins)",
      width: 120,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>{params.value}</div>
            <div style={{ marginLeft: "8px", opacity: 0.5 }}>mins</div>
          </div>
        );
      },
    },
    {
      field: "numberOfFeeds",
      headerName: "How many",
      width: 100,
      renderCell: (params: any) => {
        if (!params.value) return "";
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>{params.value}</div>
            <div style={{ marginLeft: "8px", opacity: 0.5 }}>deliveries</div>
          </div>
        );
      },
    },
    {
      field: "endTime",
      headerName: "End Time",
      width: 180,
      valueGetter: (params: any) =>
        params.value ? fDateTime(params.value, "yyyy/MM/dd HH:mm") : "",
      renderCell: (params: any) => {
        return (
          <div>
            <div>
              {params.value ? fDateTime(params.value, "yyyy/MM/dd HH:mm") : ""}
            </div>
            {params.value ? (
              <div style={{ opacity: 0.5, fontSize: "12px" }}>
                {fToNow(params.value)}
              </div>
            ) : (
              ""
            )}
          </div>
        );
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => {
              setTargetFeeder(params.row);
              setOpenFeederFormDialog(true);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => {
              setTargetFeeder(params.row);
              setOpenDeleteFeederConfirmDialog(true);
            }}
          >
            <DeleteIcon />
          </IconButton>
          <GeneralPopoverWrapper
            triggerElement={
              <HistoryIcon sx={{ cursor: "pointer", opacity: 0.7, ml: 1 }} />
            }
            popoverContent={
              <Box
                py={1}
                sx={{
                  px: "12px",
                  background: "#eee",
                  maxHeight: "450px",
                  overflow: "auto",
                }}
              >
                {params.row.actionHistory?.map(
                  (action: IActionMetadata, i: number) => (
                    <Paper key={`update-${i}`} sx={{ my: "4px" }}>
                      <ActionHistoryItem action={action} />
                    </Paper>
                  )
                )}
              </Box>
            }
          />
        </div>
      ),
    },
  ];
  const fetchFeeders = async () => {
    setLoading(true);
    const result = await feederService.getFeeders();
    setLoading(false);
    if (result) {
      if (feedersData?.length) {
        feedersData.forEach((feeder: IFeeder) => {
          const updatedFeeder = result.find(
            (p: IFeeder) => p?._id == feeder._id
          );
          if (
            updatedFeeder &&
            updatedFeeder.status == FeederStatus.Disconnected &&
            feeder.status != FeederStatus.Disconnected
          ) {
            showSnackbar(
              "danger",
              `Feeder "${feeder.name}" Disconnected`,
              `Feeder "${feeder.name}" is disconnected at ${fTime(new Date())}`,
              {
                dismiss: {
                  duration: 10000000,
                },
              }
            );
          }
          if (
            updatedFeeder &&
            updatedFeeder.status == FeederStatus.Active &&
            feeder.status == FeederStatus.Disconnected
          ) {
            showSnackbar(
              "success",
              `Feeder "${feeder.name}" Connected`,
              `Feeder "${feeder.name}" is connected at ${fTime(new Date())}`,
              {
                dismiss: {
                  duration: 10000000,
                },
              }
            );
          }
        });
      }
      feedersData = result;
      setFeeders(result);
    }
  };
  const handleDeleteFeeder = async () => {
    if (!targetFeeder?._id) return;
    setLoading(true);
    const result = await feederService.deleteFeeder(targetFeeder?._id);
    setLoading(false);
    if (result) {
      setFeeders(feeders.filter((p) => p._id != targetFeeder?._id));
      setOpenDeleteFeederConfirmDialog(false);
    }
  };
  React.useEffect(() => {
    fetchFeeders();
    const interval = setInterval(() => {
      fetchFeeders();
    }, 10000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <Box mb={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "24px",
            fontWeight: "500",
            color: "secondary.main",
          }}
        >
          Manage Feeders
          <Box
            sx={{
              ml: 1,
              fontSize: "24px",
              fontWeight: "500",
              opacity: "0.6",
              color: "secondary.main",
            }}
          >{`(${feeders.length})`}</Box>
        </Box>
      </Box>
      <WhitePanel>
        <Box
          pb={2}
          sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          <Box mr={2}>
            <SimpleSearchInput
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClear={() => setSearchTerm("")}
              size="small"
              placeholder="Search..."
            />
          </Box>

          {/* {selectedIds?.length ? (
            <Button
              startIcon={<DeleteIcon />}
              disableElevation
              variant="contained"
              sx={{ ml: 2 }}
              color="error"
            >
              Delete
            </Button>
          ) : null} */}
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <AddIconButton
              mr={0}
              onClick={() => {
                setTargetFeeder(undefined);
                setOpenFeederFormDialog(true);
              }}
            />
          </Box>
          <Button
            onClick={() => {
              setTargetFeeder(undefined);
              setOpenFeederFormDialog(true);
            }}
            startIcon={<AddCircleOutlineIcon />}
            disableElevation
            variant="outlined"
            sx={{ display: { xs: "none", sm: "flex" } }}
          >
            Add New Feeder
          </Button>
        </Box>
        <DataGridPro
          loading={loading}
          autoHeight
          // rowSelectionModel={selectedIds}
          // onRowSelectionModelChange={(changedIds: any) => {
          //   setSelectedIds(changedIds);
          // }}
          rows={feeders.filter(
            (f) =>
              !searchTerm ||
              f.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              f.token?.toLowerCase().includes(searchTerm.toLowerCase())
          )}
          columns={columns}
          disableRowSelectionOnClick
          hideFooter
        />
        {openFeederFormDialog && (
          <FeederFormDialog
            open
            feeder={targetFeeder}
            onClose={() => setOpenFeederFormDialog(false)}
            onNewCreated={(created) => {
              setFeeders([...feeders, created]);
              setOpenFeederFormDialog(false);
            }}
            onUpdated={(updatedFeeder) => {
              const tempFeeders = [...feeders];
              const targetIndex = tempFeeders.findIndex(
                (p) => p._id == updatedFeeder._id
              );
              if (targetIndex > -1) {
                tempFeeders.splice(targetIndex, 1, updatedFeeder);
                setFeeders(tempFeeders);
                setOpenFeederFormDialog(false);
              }
            }}
          />
        )}
      </WhitePanel>
      {openDeleteFeederConfirmDialog && targetFeeder ? (
        <ConfirmDialog
          title={<>Delete Feeder {`"${targetFeeder.name}"`}</>}
          confirmButtonColor={"error"}
          content={
            <>{`Are you sure you want to delete feeder "${targetFeeder.name}"?`}</>
          }
          onCancel={() => setOpenDeleteFeederConfirmDialog(false)}
          onConfirm={() => {
            handleDeleteFeeder();
          }}
          onConfirmText={<>Delete</>}
        />
      ) : null}
    </>
  );
}
