import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const AuthScreen = styled(Box)(() => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#fdfdfd",
}));

export const AuthFormContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "400px",
  maxWidth: "90%",
  marginTop: "-103px",
}));

interface AuthContainerProps {
  children: React.ReactNode;
}

export default function AuthContainer({ children }: AuthContainerProps) {
  return (
    <>
      <AuthScreen>
        <AuthFormContainer>
          <img
            src="/nestle-logo.svg"
            alt="Orugen Datalake Logo"
            style={{ width: "500px", maxWidth: "100%", marginBottom: "60px" }}
          />
          <Box sx={{ width: "100%" }}>{children}</Box>
        </AuthFormContainer>
      </AuthScreen>
    </>
  );
}
