import {
  Store,
  NOTIFICATION_TYPE,
  NOTIFICATION_CONTAINER,
  NOTIFICATION_INSERTION,
} from "react-notifications-component";

export function showSnackbar(
  type: NOTIFICATION_TYPE,
  title: string,
  message: string,
  options: {
    container?: NOTIFICATION_CONTAINER;
    insert?: NOTIFICATION_INSERTION;
    dismiss?: {
      duration?: number;
      click?: boolean;
      touch?: boolean;
      showIcon?: boolean;
    };
  } = {}
) {
  Store.addNotification({
    title,
    message,
    type: type,
    insert: options.insert || "top",
    container: options.container || "top-right",
    dismiss: {
      duration: options.dismiss?.duration || (type == "success" ? 1500 : 3000),
      click: options.dismiss?.click,
      touch: options.dismiss?.touch,
      showIcon: options.dismiss?.showIcon,
    },
  });
}
