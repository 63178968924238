/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React from "react";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { IUser } from "../../../types/user";
import UserDetailForm from "./UserDetailForm";
import { ICompany } from "../../../types/company";

export default function UserFormDialog({
  user,
  open,
  onClose,
  onUpdated,
  onNewCreated,
}: {
  user?: IUser;
  open: boolean;
  onClose: () => void;
  onUpdated?: (updateInfo: IUser) => void;
  onNewCreated?: (created: IUser) => void;
}): React.ReactElement {
  return (
    <>
      <Dialog open={open} maxWidth="sm">
        <Box sx={{ background: "white", width: "1000px", maxWidth: "100%" }}>
          <Box
            p={2}
            pb={0}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h6">
              {user ? "User Details" : "New User"}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box p={2}>
            <UserDetailForm
              user={user}
              onNewCreated={onNewCreated}
              onUpdated={onUpdated}
            />
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
