export interface IActionItem {
  key: string;
  value: string;
  originalValue: string;
}

export interface IActionMetadata {
  actionTitle: string;
  fullName: string;
  userId: string;
  time: Date;
  actionItems?: IActionItem[];
}

export enum FeederStatus {
  Disconnected = "disconnected",
  NoTask = "noTask",
  Active = "active",
  Paused = "paused",
}

export interface IFeeder {
  _id?: string;
  name?: string;
  token?: string;
  status?: FeederStatus;
  startTime?: Date;
  endTime?: Date;
  numberOfFeeds?: number;
  intervalMins?: number;
  actionHistory?: IActionMetadata[];
  feedTimes?: Date[];
  disconnectedTimes?: Date[];
  connectedTimes?: Date[];
  pausedTimes?: Date[];
  resumedTimes?: Date[];
}
