import { BrowserRouter, Route, Routes } from "react-router-dom";

import Layout from "../views/layout";
import Home from "../views/pages/home";
import Users from "../views/pages/users";
import Login from "../views/pages/auth/Login";
import Signup from "../views/pages/auth/Signup";
import { useAppContext } from "../contexts/useStorageContext";
import React from "react";
import ForgotPassword from "../views/pages/auth/forgot";

export default function Root() {
  const { state } = useAppContext();
  return (
    <BrowserRouter>
      {state.loggedInUserInfo?.email ? (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="dashboard" element={<Home />} />
            <Route path="users" element={<Users />} />
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/register" element={<Signup />}></Route>
          <Route path="*" element={<Login />}></Route>
        </Routes>
      )}
    </BrowserRouter>
  );
}
